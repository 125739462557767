export default defineComponent({
  props: {
    outlined: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup() {
    var textArr = ref([{
      type: "",
      value: ""
    }]);
    return {
      textArr: textArr
    };
  }
});